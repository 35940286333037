"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TictactoeCtrl = void 0;
const connectfour_1 = require("@games/connectfour/src/controllers/connectfour");
const ng = window.angular;
const operators_1 = require("rxjs/operators");
const appModule = ng.module('app');
class TictactoeCtrl extends connectfour_1.ConnectfourCtrl {
    constructor() {
        super(...arguments);
        this.dbName = 'tictactoe';
        this.gameOptions = {
            finishCount: 3,
            fourRow: [],
            size: { x: 3, y: 3 },
            x: null,
            y: null
        };
        // игровое поле
        this.grid = connectfour_1.ConnectfourCtrl.getGrid(3, 3);
    }
    setColRow(col, row) {
        this.gameOptions.x = null;
        this.gameOptions.y = null;
        this.playerSubject.next([col, row]);
    }
    makeGame() {
        ng.extend(this.gameOptions, {
            x: null,
            y: 0,
            fourRow: []
        });
        this.grid = connectfour_1.ConnectfourCtrl.getGrid(this.gameOptions.size.x, this.gameOptions.size.y);
        this.last_player = null;
        this.finish = null;
        this.playerSubject.pipe((0, operators_1.throttleTime)(300), (0, operators_1.filter)(() => {
            return this.player != this.last_player;
        }), (0, operators_1.filter)((value) => {
            const [x, y] = value;
            if ((x < this.gameOptions.size.y) && (y < this.gameOptions.size.y)) {
                if (!this.grid[x][y].player) {
                    return true;
                }
            }
            return false;
        }), (0, operators_1.takeUntil)(this._onFinishDestroy)).subscribe((next) => {
            const [col, row] = next;
            this.$timeout(() => {
                this.gameOptions.x = col;
                this.gameOptions.y = row;
            });
            this.$timeout(() => {
                this.grid[col][row].player = this.player;
                const winner = this.checkFinish(this.grid);
                if (winner) {
                    this.finish = winner == this.player ? `ws-${this.WsService.ws_token}` : winner;
                    this._onFinishDestroy.next(null);
                }
                // Сохранение в базу поля игры и остальные динамические! параметры
                this.gamedb.upsert(this.gameToken, (oldDoc) => {
                    return ng.equals(oldDoc, {}) ? false : ng.extend(oldDoc, {
                        gameOptions: this.gameOptions,
                        grid: this.grid,
                        last_player: this.player,
                        finish: this.finish,
                    });
                });
            }, 300);
            this.last_player = this.player;
        }, (err) => console.debug('$onInit this.playerSubject.subscribe', err));
    }
}
exports.TictactoeCtrl = TictactoeCtrl;
appModule.component('gameTictactoe', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: TictactoeCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('tictactoe/');
    }]);
